/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { node } from "prop-types";
import { getAccessToken, setAccessToken } from "../accessToken";

const UserContext = React.createContext();
export const ERROR_FEEDBACK = 'error';
export const INFO_FEEDBACK = 'info';
export const SUCCESS_FEEDBACK = 'success';


class UserProvider extends Component {
    static propTypes = {
        children: node.isRequired,
    };

    state = {
        user: {},
        availableSkills: [],
        accessToken: null,
        payoutsInfo: {
            payoutId: null,
        },
        showFeedback: false,
        feedbackType: INFO_FEEDBACK,
        feedbackMessage: ''
    };

    setUser = (user) => {
        this.setState({ user });
    };

    setShowFeedback = (showFeedback, feedbackType, feedbackMessage = 'Processing Request') => {
        this.setState({ showFeedback, feedbackType, feedbackMessage });
    };

    setAvailableSkills = (skills) => {
        this.setState({ availableSkills: skills });
    };

    setPayoutsInfo = (payoutsInfo) => {
        this.setState({ payoutsInfo });
    };

    getCtxAccessToken = () => {
        const accessToken = getAccessToken();
        if (accessToken) {
            this.setState({ accessToken });
            return accessToken;
        }
        return null;
    };

    setCtxAccessToken = (accessToken) => {
        setAccessToken(accessToken);
        this.setState({ accessToken });
    };

    render() {
        const { children } = this.props;
        const { user, availableSkills, payoutsInfo } = this.state;
        const { setUser } = this;
        const { setAvailableSkills } = this;
        const { setPayoutsInfo } = this;
        const { getCtxAccessToken } = this;
        const { setCtxAccessToken } = this;
        const { showFeedback } = this.state;
        const { feedbackType } = this.state;
        const { feedbackMessage } = this.state;
        const { setShowFeedback } = this;

        return (
            <UserContext.Provider
                value={{
                    user,
                    setUser,
                    availableSkills,
                    setAvailableSkills,
                    payoutsInfo,
                    setPayoutsInfo,
                    getCtxAccessToken,
                    setCtxAccessToken,
                    showFeedback,
                    setShowFeedback,
                    feedbackType,
                    feedbackMessage
                }}>
                {children}
            </UserContext.Provider>
        );
    }
}

export default UserContext;

export { UserProvider };
