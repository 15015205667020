import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import { func } from "prop-types";
import { Navbar, Nav, DropdownButton } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import gql from "graphql-tag";
import { withTranslation } from "react-i18next";
import { withWindowSizeObserver } from "../common/withWindowSizeObserver";
import UserContext from "../../contexts/UserContext";
import Redirector from "../common/Redirector";
import LangControl from "../lang/LangControl";
import "../../scss/components/dashboard/TopNav.scss";
import Image from "../../components/ui/Image";
import AppStoreLogo from "../../assets/images/app-store-badge-crop.png";
import PlayStoreLogo from "../../assets/images/google-play-badge-crop.png";


const GET_USER_DATA = gql`
    query GetPhotographerData {
        getPhotographerData {
            id
            firstName
            lastName
            email
            location {
                city
                state
                country
                countryCode
                googleLocation
            }
            instagramUsername
            skills {
                id
                name
            }
            ethnicity
            gender
            phantomWalletId
        }
    }
`;

class TopNav extends Component {
    static propTypes = {
        t: func.isRequired,
        breakpointSurpassed: func,
    };

    static defaultProps = {
        breakpointSurpassed: () => { },
    };

    state = {
        redirect: false,
        loading: false,
        navExpanded: false,
    };

    componentDidMount() {
        const { user } = this.context;
        if (isEmpty(user)) {
            this.fetchData();
        }
    }

    fetchData = async () => {
        try {
            this.setState({ loading: true });

            // eslint-disable-next-line react/prop-types
            const { client } = this.props;
            // eslint-disable-next-line react/prop-types
            const result = await client.query({
                query: GET_USER_DATA,
                fetchPolicy: "network-only",
            });

            const userData = result.data && result.data.getPhotographerData;
            this.setState({ loading: false });

            this.updateContext(userData);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }
    };

    updateContext = (userData) => {
        // set user data in the context
        const { setUser } = this.context;
        setUser(userData);
    };

    setNavExpanded = (expanded) => {
        this.setState({ navExpanded: expanded });
    };

    closeNav = () => {
        this.setState({ navExpanded: false });
    };

    static contextType = UserContext;

    // RENDER:
    render() {
        const { t, breakpointSurpassed } = this.props;
        const { redirect, loading, navExpanded } = this.state;
        const {
            user: { firstName: userContextFirstName = "", lastName: userContextLastName = "" },
        } = this.context || {};
        const expand = breakpointSurpassed();

        return (() => {
            if (loading) {
                return "";
            }
            if (userContextFirstName && userContextLastName) {
                const title = userContextFirstName
                    ? `${t("topNav.greeting")} ${userContextFirstName}`
                    : t("topNav.greeting");
                return (
                    <Navbar
                        id="main-nav-bar"
                        className="bg-light"
                        variant="light"
                        expand={expand}
                        expanded={navExpanded}
                        onToggle={this.setNavExpanded}>
                        <Redirector redirect={redirect} whereto="auth" />
                        {expand ? (
                            <React.Fragment>
                                <div className="top-nav-wrapper-left">
                                    <div className="see-public-profile-wrapper">
                                        <a
                                            // eslint-disable-next-line max-len
                                            href={`https://scop.io/collections/vendors?q=${userContextFirstName}+${userContextLastName}`}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            {t("topNav.highlightedOptions.seePublicProfile")}
                                        </a>
                                    </div>
                                </div>
                                <div className="top-nav-wrapper-right">
                                    <Nav>
                                        <div className="app-download-buttons">
                                            <a href="https://apps.apple.com/us/app/scopio/id6444903856" target="_blank">
                                                <Image src={AppStoreLogo} />
                                            </a>
                                            <a href="https://play.google.com/store/apps/details?id=io.scop.app&pli=1" target="_blank">
                                                <Image src={PlayStoreLogo} />
                                            </a>
                                        </div>
                                    </Nav>
                                    <Nav>
                                        <LangControl standAlone={false} />
                                    </Nav>
                                    <Nav>
                                        <div className="upload-link-wrapper">
                                            <Link className="upload-link" to="/images/submit">
                                                <span className="upload-link-text">
                                                    {t("topNav.highlightedOptions.upload")}
                                                </span>
                                            </Link>
                                        </div>
                                    </Nav>
                                    <Nav>
                                        <DropdownButton id="dropdown-basic-button" title={title}>
                                            <Link className="dropdown-item" to="/logout">
                                                {t("topNav.logOut")}
                                            </Link>
                                        </DropdownButton>
                                    </Nav>
                                </div>
                            </React.Fragment >
                        ) : (
                            <React.Fragment>
                                <div className="logo-wrapper">
                                    <Link to="/images">
                                        <span className="logo" title={t("topNav.images")} />
                                    </Link>
                                </div>
                                <div className="upload-link-wrapper">
                                    <Link className="upload-link" to="/images/submit">
                                        <span className="upload-link-text">
                                            {t("topNav.highlightedOptions.upload")}
                                        </span>
                                    </Link>
                                </div>
                            </React.Fragment>
                        )}
                        <Navbar.Toggle aria-controls="responsive-navbar-nav">
                            <FaBars />
                        </Navbar.Toggle>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            {!expand ? (
                                <React.Fragment>
                                    <div className="responsive-nav-close">
                                        <div
                                            className="responsive-nav-close-btn"
                                            role="button"
                                            tabIndex="-1"
                                            onKeyPress={this.closeNav}
                                            onClick={this.closeNav}
                                        />
                                    </div>
                                    <Nav onClick={this.closeNav}>
                                        <Link to="/account-settings">{t("topNav.accountSettings")}</Link>
                                    </Nav>
                                    <Nav onClick={this.closeNav}>
                                        <Link to="/images">{t("topNav.images")}</Link>
                                    </Nav>
                                    <Nav onClick={this.closeNav}>
                                        <Link to="/earnings">{t("topNav.earnings")}</Link>
                                    </Nav>
                                    <Nav onClick={this.closeNav}>
                                        <Link to="/images/submit">{t("topNav.upload")}</Link>
                                    </Nav>
                                    <Nav onClick={this.closeNav}>
                                        <Link to="/perks">{t("topNav.perks")}</Link>
                                    </Nav>
                                    <Nav onClick={this.closeNav}>
                                        <Link to="/community">{t("topNav.community")}</Link>
                                    </Nav>
                                    <Nav onClick={this.closeNav}>
                                        <Link to="/help">{t("topNav.helpCenter")}</Link>
                                    </Nav>
                                    <Nav onClick={this.closeNav}>
                                        <Link to="/logout">{t("topNav.logOut")}</Link>
                                    </Nav>
                                    <Nav onSelect={this.closeNav}>
                                        {t('optionsNav.downloadApp')}

                                        <div className="app-download-buttons-collapse">
                                            <a href="https://apps.apple.com/us/app/scopio/id6444903856" target="_blank">
                                                <Image src={AppStoreLogo} />
                                            </a>
                                            <a href="https://play.google.com/store/apps/details?id=io.scop.app&pli=1" target="_blank">
                                                <Image src={PlayStoreLogo} />
                                            </a>
                                        </div>
                                    </Nav>
                                    <Nav onSelect={this.closeNav}>
                                        <LangControl standAlone={false} />
                                    </Nav>
                                </React.Fragment>
                            ) : (
                                ""
                            )}
                        </Navbar.Collapse>
                    </Navbar >
                );
            }
            return "";
        })();
    }
}

export default withRouter(withWindowSizeObserver(withTranslation("common")(TopNav)));
