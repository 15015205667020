import jwtDecode from "jwt-decode";

let accessToken = "";

export const setAccessToken = (token) => {
    accessToken = token;
};

export const getAccessToken = () => accessToken;

export const validateAccessToken = (token) => {
    if (!token) {
        return false;
    }

    try {
        const { exp } = jwtDecode(token);
        // check if the token has expired
        if (Date.now() >= exp * 1000) {
            return false;
        }
        return true;
    } catch {
        return false;
    }
};

export const fetchAccessToken = () =>
    fetch(`${process.env.REACT_APP_SERVER}${process.env.REACT_APP_REFRESH_TOKEN_PATH}`, {
        method: "POST",
        credentials: "include",
    });

export const retrieveAccessToken = async () => {
    const isValidAccessToken = validateAccessToken(accessToken);
    if (isValidAccessToken) {
        return accessToken;
    }

    const newAccessTokenResponse = await fetchAccessToken();
    if (newAccessTokenResponse && newAccessTokenResponse.ok) {
        const responseJson = await newAccessTokenResponse.json();

        if (responseJson && responseJson.ok) {
            const { accessToken: newAccessToken } = responseJson;
            accessToken = newAccessToken;
            return newAccessToken;
        }
    }
    return null;
};
