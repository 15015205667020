/* eslint-disable max-len */
import React, { Component, Suspense } from "react";
import "../../scss/components/dashboard/Dashboard.scss";
import { func, string } from "prop-types";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { withTranslation } from "react-i18next";
import { withWindowSizeObserver } from "../common/withWindowSizeObserver";
import TopNav from "./TopNav";
import OptionsNav from "./OptionsNav";
import Redirector from "../common/Redirector";

const IS_USER_AUTH = gql`
    query IsUserAuth {
        isUserAuth
    }
`;

export const DASHBOARD_MENU_OPTIONS = {
    SETTINGS: "Settings",
    PHOTO_GRID: "PhotoGrid",
    PAYOUTS: "Payouts",
    TAX_SETTINGS: "TaxSettings",
    PHOTO_SUBMISSIONS: "PhotoSubmissionIframe",
    PERKS: "Perks",
    HELP_CENTER: "HelpCenter",
    COMMUNITY: "Community"
}
const Settings = React.lazy(() => import("../account/Settings"));
const PhotoGrid = React.lazy(() => import("./photoView/PhotoGrid"));
const Payouts = React.lazy(() => import("./payouts/Payouts"));
const TaxSettings = React.lazy(() => import("./taxes/TaxSettings"));
const PhotoSubmissionIframe = React.lazy(() => import("./photosSubmission/PhotoSubmissionIframe"));
const HelpCenter = React.lazy(() => import("./HelpCenter"));
const Perks = React.lazy(() => import("./Perks"));
const Submissions = React.lazy(() => import("./photosSubmission/Submission"));
const Community = React.lazy(() => import('./Community'));

class Dashboard extends Component {
    static propTypes = {
        t: func.isRequired,
        loadComponent: string,
        breakpointSurpassed: func,
        showOption: string
    };

    static defaultProps = {
        showOption: "",
        loadComponent: "",
        breakpointSurpassed: () => { },
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            isUserAuth: false,
            redirect: false,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        try {
            // eslint-disable-next-line react/prop-types
            const { client } = this.props;
            // eslint-disable-next-line react/prop-types
            const result = await client.query({
                query: IS_USER_AUTH,
                fetchPolicy: "network-only",
            });

            if (result.data && "isUserAuth" in result.data) {
                this.setState({
                    isUserAuth: result.data.isUserAuth,
                    redirect: !result.data.isUserAuth,
                });
            }
            this.setState({ loading: false });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }
    };

    requestRedirect = () => {
        this.setState({ redirect: true });
    };

    // RENDER:
    render() {
        const { isUserAuth, loading, redirect } = this.state;
        const { loadComponent, showOption } = this.props;
        return (
            <section className="dashboard">
                <Redirector redirect={redirect} whereto="auth" />
                {(() => {
                    if (loading) {
                        return "";
                    }
                    if (isUserAuth) {
                        return (
                            <div className="dashboard-content">
                                <OptionsNav />
                                <div className="main-wrapper">
                                    <TopNav {...this.props} />
                                    <div className="content">
                                        <React.Fragment>
                                            {(() => {
                                                if (loadComponent === DASHBOARD_MENU_OPTIONS.SETTINGS) {
                                                    return (
                                                        <Suspense fallback="">
                                                            <Settings redirectCallBack={this.requestRedirect} />
                                                        </Suspense>
                                                    );
                                                }

                                                if (loadComponent === DASHBOARD_MENU_OPTIONS.PHOTO_GRID) {
                                                    return (
                                                        <Suspense fallback="">
                                                            <PhotoGrid redirectCallBack={this.requestRedirect} showOption={showOption} />
                                                        </Suspense>
                                                    );
                                                }

                                                if (loadComponent === DASHBOARD_MENU_OPTIONS.PAYOUTS) {
                                                    return (
                                                        <Suspense fallback="">
                                                            <Payouts redirectCallBack={this.requestRedirect} />
                                                        </Suspense>
                                                    );
                                                }

                                                if (loadComponent === DASHBOARD_MENU_OPTIONS.TAX_SETTINGS) {
                                                    return (
                                                        <Suspense fallback="">
                                                            <TaxSettings redirectCallBack={this.requestRedirect} />
                                                        </Suspense>
                                                    );
                                                }

                                                if (loadComponent === DASHBOARD_MENU_OPTIONS.PHOTO_SUBMISSIONS) {
                                                    return (
                                                        <Suspense fallback="">
                                                            <Submissions />
                                                        </Suspense>
                                                    );
                                                }

                                                if (loadComponent === DASHBOARD_MENU_OPTIONS.PERKS) {
                                                    return (
                                                        <Suspense fallback="">
                                                            <Perks redirectCallBack={this.requestRedirect} />
                                                        </Suspense>
                                                    );
                                                }

                                                if (loadComponent === DASHBOARD_MENU_OPTIONS.HELP_CENTER) {
                                                    return (
                                                        <Suspense fallback="">
                                                            <HelpCenter redirectCallBack={this.requestRedirect} />
                                                        </Suspense>
                                                    );
                                                }


                                                if (loadComponent === DASHBOARD_MENU_OPTIONS.COMMUNITY) {
                                                    return (
                                                        <Suspense fallback="">
                                                            <Community redirectCallBack={this.requestRedirect} />
                                                        </Suspense>
                                                    );
                                                }

                                                return "";
                                            })()}
                                        </React.Fragment>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    return "";
                })()}
            </section>
        );
    }
}

export default withApollo(withWindowSizeObserver(withTranslation("common")(Dashboard)));
