const internationalize = {
    changeLang(lang, store = false) {
        const { i18n } = this.props;
        i18n.changeLanguage(lang);
        this.setState({ selectedLanguage: lang });

        if (store) {
            window.localStorage.setItem("lang", lang);
        }
    },

    checkLang() {
        const { i18n } = this.props;
        const lang = window.localStorage.getItem("lang") || i18n.language || "";
        if (lang) {
            this.changeLanguage(lang);
        }
    },

    getTranslatedLang() {
        const { i18n } = this.props;
        const selectedLanguage = window.localStorage.getItem("lang") || i18n.language || "";
        let lang = "";
        switch (selectedLanguage) {
            case "sp":
                lang = "es";
                break;
            default:
                lang = selectedLanguage;
        }
        return lang;
    },
};

export default internationalize;
