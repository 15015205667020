/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "../../scss/components/dashboard/OptionsNav.scss";
import { func } from "prop-types";
import { withTranslation } from "react-i18next";
import SideNav, { NavItem, NavIcon } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
// import { GiReceiveMoney } from "react-icons/gi";
// import { IconContext } from "react-icons";
import { withWindowSizeObserver } from "../common/withWindowSizeObserver";
import photosView from "../../assets/images/photo_view.svg";
import logo from "../../assets/images/logo_scopio.png";
import uploadIcon from "../../assets/images/upload_icon.svg";
import profileIcon from "../../assets/images/profile_icon.svg";
import logOutIcon from "../../assets/images/logout_icon.svg";
import perksIcon from "../../assets/images/perks_icon.svg";
import helpIcon from "../../assets/images/help_icon.svg";
import earningsIcon from "../../assets/images/earnings_icon.svg";
import communityIcon from "../../assets/images/community_icon.svg";

class OptionsNav extends Component {
    static propTypes = {
        t: func.isRequired,
    };

    constructor(props) {
        super(props);

        this.defaultPath = "/dashboard";
        this.defaultSelectedOpt = "/images";
        this.menuOpts = [
            {
                route: "/images/submit",
                titleTag: "optionsNav.upload",
                content: (
                    <NavIcon>
                        <div className="opt-icon">
                            <img src={uploadIcon} alt="" />
                        </div>
                    </NavIcon>
                ),
            },
            {
                route: "/images",
                titleTag: "optionsNav.images",
                content: (
                    <NavIcon>
                        <div className="opt-icon">
                            <img src={photosView} alt="" />
                        </div>
                    </NavIcon>
                ),
            },
            {
                route: "/account-settings",
                titleTag: "optionsNav.accountSettings",
                content: (
                    <NavIcon>
                        <div className="opt-icon">
                            <img src={profileIcon} alt="" />
                        </div>
                    </NavIcon>
                ),
            },
            {
                route: "/earnings",
                titleTag: "optionsNav.earnings",
                content: (
                    <NavIcon>
                        <div className="opt-icon">
                            <img src={earningsIcon} alt="" />
                        </div>
                    </NavIcon>
                ),
            },
            {
                route: "/perks",
                titleTag: "optionsNav.perks",
                content: (
                    <NavIcon>
                        <div className="opt-icon">
                            <img src={perksIcon} alt="" />
                        </div>
                    </NavIcon>
                ),
            },
            {
                route: "/community",
                titleTag: "optionsNav.community",
                content: (
                    <NavIcon>
                        <div className="opt-icon">
                            <img src={communityIcon} alt="" />
                        </div>
                    </NavIcon>
                ),
            },
            {
                route: "/help",
                titleTag: "optionsNav.helpCenter",
                content: (
                    <NavIcon>
                        <div className="opt-icon">
                            <img src={helpIcon} alt="" />
                        </div>
                    </NavIcon>
                ),
            },
            {
                route: "/logout",
                titleTag: "optionsNav.logOut",
                cssClasses: "logout",
                content: (
                    <NavIcon>
                        <div className="opt-icon">
                            <img src={logOutIcon} alt="" />
                        </div>
                    </NavIcon>
                ),
            },
        ];
    }

    state = {
        selectedOpt: "",
    };

    componentDidMount() {
        this.checkRouteMatch();
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props;
        if (location && location !== prevProps.location) {
            this.checkRouteMatch();
        }
    }

    checkRouteMatch = () => {
        const { location } = this.props;

        if (location && location.pathname) {
            const path = location.pathname;
            this.setState({ selectedOpt: path });
        }
    };

    isActive = (eventKey) => {
        const { selectedOpt } = this.state;
        if (selectedOpt === this.defaultPath) {
            if (eventKey === this.defaultSelectedOpt) {
                return true;
            }
        } else if (eventKey === selectedOpt) {
            return true;
        }
        return false;
    };

    onSelectedOptChanged = (selected) => {
        const { location, history } = this.props;
        const to = selected;
        if (location.pathname !== to) {
            history.push(to);
        }
    };

    render() {
        const { t, breakpointSurpassed } = this.props;

        return breakpointSurpassed() ? (
            <SideNav id="options-nav">
                <SideNav.Nav onSelect={this.onSelectedOptChanged}>
                    <div className="scopio-logo-container">
                        <Link to="/images">
                            <img alt="Scopio Logo" className="logo" title={t("optionsNav.images")} src={logo} />
                        </Link>
                    </div>
                    {this.menuOpts.map((item) => {
                        const defaultCssClass = "opt-nav-item";
                        const className = item.cssClasses
                            ? [defaultCssClass, ...item.cssClasses.split(" ")].join(" ")
                            : defaultCssClass;
                        return (
                            <NavItem
                                key={item.route}
                                eventKey={item.route}
                                navitemClassName={className}
                                title={item.titleTag ? t(item.titleTag) : item.title}
                                active={this.isActive(item.route)}>
                                {item.content}
                            </NavItem>
                        );
                    })}
                </SideNav.Nav>
            </SideNav>
        ) : (
            ""
        );
    }
}

export default withWindowSizeObserver(withRouter(withTranslation("common")(OptionsNav)));
