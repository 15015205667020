/* eslint-disable import/prefer-default-export */
import React, { Component } from "react";

export function withWindowSizeObserver(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);

            this.state = {
                windowWidth: 0,
                windowHeight: 0,
                breakpoint: 700,
            };

            this.breakpointSurpassed = this.breakpointSurpassed.bind(this);
            this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        }

        componentDidMount() {
            this.updateWindowDimensions();
            window.addEventListener("resize", this.updateWindowDimensions);
        }

        componentWillUnmount() {
            window.removeEventListener("resize", this.updateWindowDimensions);
        }

        breakpointSurpassed() {
            const { windowWidth, breakpoint } = this.state;
            if (windowWidth > breakpoint) {
                return true;
            }
            return false;
        }

        updateWindowDimensions() {
            this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
        }

        render() {
            const { windowWidth, windowHeight, breakpoint } = this.state;
            return (
                <WrappedComponent
                    windowWidth={windowWidth}
                    windowHeight={windowHeight}
                    breakpoint={breakpoint}
                    breakpointSurpassed={this.breakpointSurpassed}
                    {...this.props}
                />
            );
        }
    };
}
