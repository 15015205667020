import React, { Component } from "react";
import { func, string, bool } from "prop-types";
import { withTranslation } from "react-i18next";
import "../../scss/components/ui/ImagePlaceholder.scss";
import Loading from "./Loading";
import brokenImg from "../../assets/images/broken_image.png";

class ImagePlaceholder extends Component {
    static propTypes = {
        // eslint-disable-next-line react/no-unused-prop-types
        t: func.isRequired,
        src: string,
        alt: string,
        imgIsBroken: bool,
    };

    static defaultProps = {
        alt: "",
        src: "",
        imgIsBroken: false,
    };

    render() {
        const { alt, src, imgIsBroken } = this.props;
        const loadingCssClasses = !alt ? "no-info" : "";

        return (
            <div className="img-placeholder">
                {!imgIsBroken ? (
                    <Loading cssClasses={loadingCssClasses} />
                ) : (
                    <div className="broken-image">
                        <img src={brokenImg} alt="Broken link." title="Broken link." />
                    </div>
                )}
                <div className="img-info">
                    <p className="msg" />
                    {alt ? <p className="alt">{alt}</p> : ""}
                    {src ? <p className="src">{src}</p> : ""}
                </div>
            </div>
        );
    }
}

export default withTranslation("common")(ImagePlaceholder);
