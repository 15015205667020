import React from "react";
import "../scss/components/App.scss";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import BetaBanner from "./BetaBanner";
import { DASHBOARD_MENU_OPTIONS } from "./dashboard/Dashboard";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { Redirect } from "react-router";

const Landing = React.lazy(() => import("./account/Landing"));
const ResetPwd = React.lazy(() => import("./account/ResetPwd"));
const ConfirmEmail = React.lazy(() => import("./account/ConfirmEmail"));
const Dashboard = React.lazy(() => import("./dashboard/Dashboard"));
const ConnectToPaypalResponseHandler = React.lazy(() => import("./dashboard/payouts/ConnectToPaypalResponseHandler"));
const LogOut = React.lazy(() => import("./account/LogOut"));
const PageNotFound = React.lazy(() => import("../components/common/PageNotFound"));
const ModelRelease = React.lazy(() => import("./modelrelease/ModelRelease"));

const theme = createTheme({
    palette: {
        primary: {
            main: '#641bcc',
        }
    },
});
function App() {
    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <BrowserRouter>
                    <BetaBanner />
                    <Switch>
                        <Route path="/" exact render={(props) => <Landing {...props} activeIndex="1" />} />
                        <Route path="/signup" render={(props) => <Landing {...props} activeIndex="1" />} />
                        <Route path="/login" render={(props) => <Landing {...props} activeIndex="2" />} />
                        <Route path="/forgot" render={(props) => <Landing {...props} activeIndex="3" />} />
                        <Route path="/reset/account" render={(props) => <ResetPwd {...props} />} />
                        <Route path="/confirm/email" render={(props) => <ConfirmEmail {...props} />} />
                        <Route path="/images/pending" render={(props) => <Dashboard {...props} loadComponent={DASHBOARD_MENU_OPTIONS.PHOTO_GRID} showOption="pending" />} />
                        <Route path="/images/published" render={(props) => <Dashboard {...props} loadComponent={DASHBOARD_MENU_OPTIONS.PHOTO_GRID} showOption="approved" />} />
                        <Route path="/images/rejected" render={(props) => <Dashboard {...props} loadComponent={DASHBOARD_MENU_OPTIONS.PHOTO_GRID} showOption="rejected" />} />
                        <Route path="/images/submit" render={(props) => <Dashboard {...props} loadComponent={DASHBOARD_MENU_OPTIONS.PHOTO_GRID} showOption="onSubmit" />} />
                        <Route path="/images/onSubmit" render={(props) => <Redirect to="/images/submit" />} />
                        <Route path="/images" render={(props) => <Dashboard {...props} loadComponent={DASHBOARD_MENU_OPTIONS.PHOTO_GRID} />} />
                        <Route
                            path="/account-settings"
                            render={(props) => <Dashboard {...props} loadComponent={DASHBOARD_MENU_OPTIONS.SETTINGS} />}
                        />
                        <Route path="/earnings" render={(props) => <Dashboard {...props} loadComponent={DASHBOARD_MENU_OPTIONS.PAYOUTS} />} />
                        <Route
                            path="/payment-gateway-connect"
                            render={(props) => <ConnectToPaypalResponseHandler {...props} />}
                        />
                        <Route
                            path="/tax-settings"
                            render={(props) => <Dashboard {...props} loadComponent={DASHBOARD_MENU_OPTIONS.TAX_SETTINGS} />}
                        />
                        <Route
                            path="/upload"
                            render={(props) => <Dashboard {...props} loadComponent={DASHBOARD_MENU_OPTIONS.PHOTO_SUBMISSIONS} />}
                        />
                        <Route path="/help" render={(props) => <Dashboard {...props} loadComponent={DASHBOARD_MENU_OPTIONS.HELP_CENTER} />} />
                        <Route path="/perks" render={(props) => <Dashboard {...props} loadComponent={DASHBOARD_MENU_OPTIONS.PERKS} />} />
                        <Route path="/community" render={(props) => <Dashboard {...props} loadComponent={DASHBOARD_MENU_OPTIONS.COMMUNITY} />} />

                        <Route path="/logout" render={(props) => <LogOut {...props} />} />
                        <Route path="/modelrelease/:modelid+" render={(props) => <ModelRelease {...props} />} />
                        <Route path="/not-found" render={(props) => <PageNotFound {...props} />} />
                        <Route path="*" exact render={(props) => <PageNotFound {...props} />} />
                    </Switch>
                </BrowserRouter>
            </div>
        </ThemeProvider>
    );
}

export default App;
