import React, { Component } from "react";
import { func, bool } from "prop-types";
import "../../scss/components/lang/LangControl.scss";
import { Dropdown } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import in18n from "./internationalize";

class LangControl extends Component {
    static propTypes = {
        t: func.isRequired,
        standAlone: bool,
    };

    static defaultProps = {
        standAlone: true,
    };

    constructor(props) {
        super(props);

        this.changeLanguage = in18n.changeLang.bind(this);
        this.checkLanguage = in18n.checkLang.bind(this);
    }

    state = {
        selectedLanguage: "",
    };

    componentDidMount() {
        const { t } = this.props;
        this.setState({ selectedLanguage: t("en") }, () => {
            this.checkLanguage();
        });
    }

    clickHandler = (lang) => {
        this.changeLanguage(lang, true);
    };

    render() {
        const { t, standAlone } = this.props;
        const { selectedLanguage } = this.state;
        const defaultCssClasses = "lang-control inline";
        const className = standAlone ? [...defaultCssClasses.split(" "), "stand-alone"].join(" ") : defaultCssClasses;

        return (
            <Dropdown className={className}>
                <Dropdown.Toggle id="dropdown-basic" variant="none" className="btn-lang d-flex align-items-center">
                    <div className="selected-lang d-inline-flex">{t(selectedLanguage)}</div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item
                        onClick={() => {
                            this.clickHandler("en");
                        }}>
                        {t("en")}
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => {
                            this.clickHandler("sp");
                        }}>
                        {t("sp")}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default withTranslation("translation")(LangControl);
