import React, { Component } from "react";
import { string } from "prop-types";
import "../../scss/components/ui/Loading.scss";

class Loading extends Component {
    static propTypes = {
        cssClasses: string,
    };

    static defaultProps = {
        cssClasses: "",
    };

    componentDidMount() {
        const img = new Image();
        img.src = "../../../public/images/loading.gif";
    }

    render() {
        const defaultCssClasses = "loading";
        const { cssClasses } = this.props;

        const className = cssClasses
            ? [...cssClasses.split(" "), ...defaultCssClasses.split(" ")].join(" ")
            : defaultCssClasses;

        return (
            <div className={className}>
                <img alt="Loading..." src="/images/loading.gif" />
            </div>
        );
    }
}

export default Loading;
