import React, { Component } from "react";
import { bool, string } from "prop-types";
import { Redirect } from "react-router-dom";

class Redirector extends Component {
    static propTypes = {
        redirect: bool,
        whereto: string.isRequired,
    };

    static defaultProps = {
        redirect: false,
    };

    constructor(props) {
        super(props);

        this.destinations = {
            auth: "/login",
            home: "/images",
            notFound: "/not-found"
        };
    }

    componentDidMount() {}

    render() {
        const { redirect, whereto } = this.props;

        return (() => {
            if (redirect) {
                return <Redirect to={this.destinations[whereto]} />;
            }
            return "";
        })();
    }
}

export default Redirector;
