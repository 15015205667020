import React, { Component } from "react";
import { func, string, bool } from "prop-types";
import { withTranslation } from "react-i18next";
import ImagePlaceholder from "./ImagePlaceholder";
import Loading from "./Loading";

class Image extends Component {
    static propTypes = {
        // eslint-disable-next-line react/no-unused-prop-types
        t: func.isRequired,
        src: string.isRequired,
        alt: string,
        imgCssClasses: string,
        useImagePlaceholder: bool,
    };

    static defaultProps = {
        alt: "",
        imgCssClasses: "",
        useImagePlaceholder: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            imgIsBroken: false,
        };
    }

    onImgError = () => {
        this.setState({ imgIsBroken: true });
    };

    onImgLoaded() {
        this.setState({ loading: false });
    }

    fallbackJSX = () => {
        const { useImagePlaceholder, src, alt } = this.props;
        const { loading, imgIsBroken } = this.state;
        if (useImagePlaceholder) {
            if (loading || imgIsBroken) {
                return <ImagePlaceholder alt={alt} src={src} imgIsBroken={imgIsBroken} />;
            }
        } else {
            if (loading) {
                return <Loading />;
            }

            if (imgIsBroken) {
                return <div>Broken Image</div>;
            }
        }
        return "";
    };

    render() {
        const { loading, imgIsBroken } = this.state;
        const { src, imgCssClasses, alt } = this.props;

        const imageStyle = loading || imgIsBroken ? { display: "none" } : {};
        return (
            <div className="img-wrapper">
                {loading || imgIsBroken ? this.fallbackJSX() : ""}
                <img
                    alt={alt}
                    src={src}
                    className={imgCssClasses}
                    style={imageStyle}
                    onLoad={this.onImgLoaded.bind(this)}
                    onError={this.onImgError}
                />
            </div>
        );
    }
}

export default withTranslation("common")(Image);
