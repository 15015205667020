/* eslint-disable no-console */
/* eslint-disable no-shadow */
import "dotenv/config";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "font-awesome/css/font-awesome.min.css";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
//import { HttpLink } from "apollo-link-http";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "apollo-link-error";
import { ApolloLink, Observable, split } from "apollo-link";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { I18nextProvider } from "react-i18next";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch, faChevronDown, faAngleDown, faBars } from "@fortawesome/free-solid-svg-icons";
import { UserProvider } from "./contexts/UserContext";
import { getAccessToken, setAccessToken, validateAccessToken, fetchAccessToken } from "./accessToken";
import i18n from "./i18n";
import * as serviceWorker from "./serviceWorker";
import App from "./components/App";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";

library.add(faSearch, faChevronDown, faAngleDown, faBars);

const cache = new InMemoryCache({});

const wsLink = new WebSocketLink({
    uri: `${process.env.REACT_APP_WS_SERVER}/graphql`,
    credentials: 'include',
    options: {
        reconnect: true,
        connectionParams: {
            authToken: getAccessToken(),
        },
    },
});

const subscriptionMiddleware = {
    applyMiddleware: (options, next) => {
        options.authToken = getAccessToken();
        next();
    },
};

wsLink.subscriptionClient.use([subscriptionMiddleware]);

const requestLink = new ApolloLink(
    (operation, forward) =>
        new Observable((observer) => {
            let handle;
            Promise.resolve(operation)
                .then((operation) => {
                    const accessToken = getAccessToken();
                    if (accessToken) {
                        operation.setContext({
                            headers: {
                                Authorization: accessToken ? `Bearer ${accessToken}` : "",
                            },
                        });
                    }
                })
                .then(() => {
                    handle = forward(operation).subscribe({
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer),
                    });
                })
                .catch(observer.error.bind(observer));

            return () => {
                if (handle) {
                    handle.unsubscribe();
                }
            };
        }),
);



const link = split(
    ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    ApolloLink.from([
        new TokenRefreshLink({
            accessTokenField: 'accessToken',
            isTokenValidOrUndefined: () => {
                const token = getAccessToken();
                return validateAccessToken(token);
            },
            fetchAccessToken: () => fetchAccessToken(),
            handleFetch: (accessToken) => {
                setAccessToken(accessToken);
            },
            // eslint-disable-next-line no-unused-vars
            handleError: (err) => {
                // console.warn("Your refresh token is invalid. Try to relogin.");
                // console.error(err);
            },
        }),
        // eslint-disable-next-line no-unused-vars
        onError(({ graphQLErrors, networkError }) => {
            // console.log(graphQLErrors);
            // console.log(networkError);
        }),
        requestLink,
        createUploadLink({
            uri: `${process.env.REACT_APP_SERVER}/graphql`,
            credentials: 'include',
        }),
        // terminatingLink,
    ])
);

const client = new ApolloClient({
    link,
    cache,
});


ReactDOM.render(
    <React.StrictMode>
        <UserProvider>
            <ApolloProvider client={client}>
                <I18nextProvider i18n={i18n}>
                    <Suspense fallback={null}>
                        <App />
                    </Suspense>
                </I18nextProvider>
            </ApolloProvider>
        </UserProvider>
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
