import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import "../../scss/beta-banner.scss";

const BetaBanner = () => {
  const [opened, setOpened] = useState(false);
  const [translateX, setTranslateX] = useState(0);

  useEffect(() => {
    const lastCancelBetaBanner = localStorage.getItem("lastCancelBetaBanner");
    const showBanner =
      !lastCancelBetaBanner ||
      moment(lastCancelBetaBanner)
        .add(1, "day")
        .isBefore(moment());
    setOpened(showBanner);
  }, []);

  const tryLater = useCallback(() => {
    localStorage.setItem("lastCancelBetaBanner", moment().format());
    setOpened(false);
  }, []);

  const chooseAnOption = useCallback(() => {
    setTimeout(() => setTranslateX(-32), 0);
    setTimeout(() => setTranslateX(32), 100);
    setTimeout(() => setTranslateX(-16), 200);
    setTimeout(() => setTranslateX(16), 300);
    setTimeout(() => setTranslateX(-8), 400);
    setTimeout(() => setTranslateX(8), 500);
    setTimeout(() => setTranslateX(-4), 600);
    setTimeout(() => setTranslateX(4), 700);
    setTimeout(() => setTranslateX(0), 800);
  }, []);

  return (
    <>
      <div
        className="try-beta-banner"
        style={{
          top: opened ? 0 : "-100%",
        }}>
        <h1>{"Next Generation Contributor Portal"}</h1>
        <p>{"Experience a sleek, modern interface—the future of Scopio. Be among the first to explore!"}</p>
        <p className="small">{"Important: The current portal will be deprecated soon!"}</p>
        <div style={{ transition: "transform 100ms linear", transform: `translateX(${translateX}px)` }}>
          <a href="https://artist.scop.io/contributor/sign-in">
            <button className="try-beta-button try-beta-button-now">Try Now</button>
          </a>
          <button className="try-beta-button try-beta-button-later" onClick={() => tryLater()}>
            {"Later"}
          </button>
        </div>
      </div>
      <div
        className="try-beta-veil"
        style={{
          pointerEvents: opened ? "auto" : "none",
          opacity: opened ? 1 : 0,
        }}
        onClick={() => chooseAnOption()}
      />
    </>
  );
};

export default BetaBanner;
